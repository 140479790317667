import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@dft/security/service/auth.service';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { STATUS_ENTREGA_MAP, StatusEntregaEnum } from '@dft/shared/enums/status-entrega.enum';
import { TipoPerfilEnum } from '@dft/shared/enums/tipo-perfil.enum';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { Entrega } from '@dft/shared/models/entrega';
import { CategoriaServicoService } from '@dft/shared/services/categoria-servico.service';
import { EntregaService } from '@dft/shared/services/entrega.service';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'dft-entrega-dados-gerais',
  templateUrl: './entrega-dados-gerais.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaDadosGeraisComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() entrega: Entrega;

  categorias$: Observable<CategoriaServico[]>;
  temVinculoDimensionamento$ = of(false);
  isGestorGeral$: Observable<boolean> = this.authService.perfilSelecionado$.pipe(
    map(perfil => perfil && perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL));

  statusMap = STATUS_ENTREGA_MAP;
  statusEnum = StatusEntregaEnum;
  isNovaEntrega = true;
  isNaoValidada = false;

  constructor(
    private mensagemService: MensagemService,
    private categoriaService: CategoriaServicoService,
    private entregaService: EntregaService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // Controla a mudança de categoria de serviço
    this.form.controls.categoriaServico.valueChanges.subscribe(
      val => this.buscarSugestaoCodigoEntregaPorCategoria(val)
    );

    // Controla a obrigatoriedade do campo "Justificativa"
    this.form.controls.status.valueChanges.subscribe(val => {
      const control = this.form.controls.justificativa;
      if (val && val.id === 'INATIVA') {
        control.setValidators([Validators.required]);
      } else {
        control.setValue(null);
        control.clearValidators();
      }
      control.updateValueAndValidity();
    });

    if (this.entrega.id) {
      this.temVinculoDimensionamento$ = this.entregaService.verificaEntregaTemVinculo(this.entrega);
      this.isNovaEntrega = false;
      this.isNaoValidada = this.entrega.status === StatusEntregaEnum.NAO_VALIDADA;
    }

    // Corrigindo exibição do status
    this.form.controls.status.setValue(this.statusMap.find(s => s.id === this.entrega.status));

    this.categorias$ = this.categoriaService.listarCategorias().pipe(
      map(categorias => categorias.filter(c => c.tipo === TipoMetodologiaEnum.TIPICA)),
      tap(categorias => {
        if (!this.isNovaEntrega) {
          const categoriaSelecionada = categorias.find(c => c.id === this.entrega.categoriaServico.id);
          this.form.controls.categoriaServico.setValue(categoriaSelecionada);
        }
      }),
      catchError(err => {
        this.mensagemService.erro(err);
        return [];
      })
    );
  }

  private buscarSugestaoCodigoEntregaPorCategoria(categoria?: CategoriaServico) {
    const idCategoriaServico = this.entrega.categoriaServico && this.entrega.categoriaServico.id || NaN;
    const isCategoriaSelecionadaDiferenteDaEntrega = categoria && categoria.id !== idCategoriaServico;
    const isNovaEntrega = !this.entrega.id;
    if (isCategoriaSelecionadaDiferenteDaEntrega && (isNovaEntrega || this.isNaoValidada)) {
      this.categoriaService
        .buscarSugestaoCodigoEntregaPorCategoria(categoria.id)
        .subscribe((result) => {
          this.form.controls.codigo.setValue(result ? result[0] : '');
          this.form.controls.codBaseEntrega.setValue(result ? result[1] : '');
        });
    }
  }
}
