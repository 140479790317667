import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TipoAutenticador } from '@dft/shared/enums/tipo-autenticador.enum';
import { TipoPerfilEnum } from '@dft/shared/enums/tipo-perfil.enum';
import { PessoaSessao } from '@dft/shared/models/usuario-sessao';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PessoaPerfil } from 'src/app/shared/models/pessoa-perfil';
import { PessoaPerfilService } from 'src/app/shared/services/pessoa-perfil.service';
import { PessoaService } from 'src/app/shared/services/pessoa.service';
import { environment } from 'src/environments/environment';
import { SeadAuthService } from './sead-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  pessoaLogada$ = new BehaviorSubject<PessoaSessao>(null);
  perfilSelecionado$ = new BehaviorSubject<PessoaPerfil>(null);

  constructor(
    private pessoaService: PessoaService,
    private pessoaPerfilService: PessoaPerfilService,
    private mensagemService: MensagemService,
    private http: HttpClient,
    private router: Router,
    private oauthService: OAuthService,
    private seadAuthService: SeadAuthService,
  ) { }

  /**
   * Checa se a sessão atual está ativa.
   * Caso positivo, nada faz.
   * Caso negativo, redireciona para a tela de login.
   */
  checkSessaoAtiva(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.urlApi}/pessoas/sessao-ativa`);
  }

  /**
   * Prepara a requisição de checagem da sessão.
   *
   * Estas função é útil particularmente para o <pre>auth.guard.ts</pre>.
   */
  carregarSessaoObservable(): Observable<PessoaSessao> {
    return this.http.get<PessoaSessao>(`${environment.urlApi}/pessoas/sessao`);
  }

  /**
   * Carrega as credenciais do login na inicialização do app.
   *
   * Normalmente utilizado apelas pelo app.component.ts.
   */
  carregarSessao(): void {
    // Obtém a rota dos eventos de navigation.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe((event: NavigationEnd) => {
        // Verifica se há erro a exibir nos cookies
        // ou a rota inclui alguma tela de mensagem de sucesso
        if (event.url.includes('success')) {
          return;
        }

        this.carregarSessaoObservable().subscribe(
          sessao => this.atualizarSessao(sessao),
          err => this.erroAtualizarSessao(err)
        );
      });
  }

  private atualizarSessao(sessao: PessoaSessao) {
    this.pessoaLogada$.next(sessao);
    this.perfilSelecionado$.next(sessao.perfil);
  }

  private erroAtualizarSessao(err: any) {
    const regexMsgErro = /DIMENSIONAMENTO-00[7-8-9]/; // Erros de token
    if (err.error && regexMsgErro.exec(err.error.codigoErro)) {
      // Erro de usuário sem sessão: redireciona para login
      this.router.navigate(['/login']);
    } else {
      this.mensagemService.erro(err);
    }
  }

  /**
   * Retorna a lista de perfis do usuário.
   *
   * @param idPessoa ID do usuário; se não fornecido,
   *    obtém o valor do usuário da sessão
   */
  recuperarPerfis(): Observable<PessoaPerfil[]> {
    return this.pessoaPerfilService
      .listarPerfisDePessoa(this.pessoaLogada$.value.id)
      .pipe(
        map((perfis) => [...perfis].sort((p1, p2) => p1.idPerfil - p2.idPerfil))
      );
  }

  /**
   * Atualiza o perfil do usuário logado.
   *
   * @param pessoaPerfil informações do perfil da pessoa a ser atualizada
   */
  alterarPerfilSessao(pessoaPerfil: PessoaPerfil) {
    this.pessoaService.alterarPerfil(pessoaPerfil.idDto).subscribe(
      () => {
        this.mensagemService.info('Perfil alterado com sucesso');
        this.carregarSessaoObservable().subscribe(
          sessao => this.atualizarSessao(sessao),
          err => this.erroAtualizarSessao(err)
        );
      },
      (err) => this.mensagemService.erro(err)
    );
  }

  /**
   * Encaminha requisição para o servidor para revogar
   * as credenciais do usuário no sistema e remover
   * os cookies.
   */
  logout() {
    this.oauthService.logOut();
  }

  getPessoaLogadaCpf() {
    return this.seadAuthService.getCpf();
  }

  getPessoaLogadaNome() {
    return this.seadAuthService.getGivenName();
  }

  getPessoaLogadaIdOrgao() {
    return this.perfilSelecionado$.value.idOrgao;
  }

  getPessoaLogadaIdUnidade() {
    return this.perfilSelecionado$.value.idUnidade;
  }

  isGestorGeral(): boolean {
    return this.perfilSelecionado$.getValue() &&
      this.perfilSelecionado$.getValue().descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL;
  }

  isGestorEstrategico(): boolean {
    return this.perfilSelecionado$.getValue() &&
      this.perfilSelecionado$.getValue().descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO;
  }

  isGestorOrgao(): boolean {
    return this.perfilSelecionado$.getValue() &&
      this.perfilSelecionado$.getValue().descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO;
  }

  isGestorUnidade(): boolean {
    return this.perfilSelecionado$.getValue() &&
      this.perfilSelecionado$.getValue().descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE;
  }

  isUsuarioLogadoPromise() {
    return new Promise<boolean>((resolve) => {
      if (this.pessoaLogada$.value) {
        resolve(true);
      }

      setTimeout(() => resolve(!!this.pessoaLogada$.value), 1000);
    });
  }

  isAutenticacaoLocal(): boolean {
    return environment.autenticacao.tipo === TipoAutenticador.LOCAL;
  }

  isCaptchaHabilitado(): Observable<boolean> {
    return this.http.get<{ recaptcha: string }>(`${environment.urlApi}/health`)
      .pipe(map(result => result.recaptcha === 'ativado'));
  }
}
