import { Orgao } from './orgao';

export class Vinculo {
  public id: number;
  public nome: string;
  public orgao: Orgao;

  constructor(
    id?: number,
    nome?: string,
    orgao?: Orgao,
  ) {
    this.id = id;
    this.nome = nome;
    this.orgao = orgao;
  }
}
