import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'dft-multiselect',
  templateUrl: './multiselect-wrapper.component.html',
  styleUrls: ['./multiselect-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiselectWrapperComponent,
      multi: true,
    },
  ],
})
export class MultiselectWrapperComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit {

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'descricao',
    allowSearchFilter: false,
    enableCheckAll: true,
    noDataAvailablePlaceholderText: '',
    closeDropDownOnSelection: true,
    selectAllText: 'Selecione todas',
    searchPlaceholderText: 'Pesquisar',
    unSelectAllText: 'Desmarque todas',
    itemsShowLimit: 2,
  };

  // Atributos de dropdownSettings
  @Input() set selecaoUnica(input: boolean) {
    if (input !== this.dropdownSettings.singleSelection) {
      this.dropdownSettings.singleSelection = input;
    }
  }

  @Input() set permitirFiltroDePesquisa(input: boolean) {
    if (input !== this.dropdownSettings.allowSearchFilter) {
      this.dropdownSettings.allowSearchFilter = input;
    }
  }

  @Input() set textoSelecionarTudo(input: string) {
    if (input !== this.dropdownSettings.selectAllText) {
      this.dropdownSettings.selectAllText = input;
    }
  }

  @Input() set textoSemDadosDisponiveis(input: string) {
    if (input !== this.dropdownSettings.noDataAvailablePlaceholderText) {
      this.dropdownSettings.noDataAvailablePlaceholderText = input;
    }
  }

  @Input() set campoIdentificador(input: string) {
    if (input !== this.dropdownSettings.idField) {
      this.dropdownSettings.idField = input;
    }
  }

  @Input() set campoDeTexto(input: string) {
    if (input !== this.dropdownSettings.textField) {
      this.dropdownSettings.textField = input;
    }
  }

  @Input() set maximoItensSelecionadosMostrados(input: number) {
    if (input !== this.dropdownSettings.itemsShowLimit) {
      this.dropdownSettings.itemsShowLimit = input;
    }
  }

  @Input() listaDeOpcoes: any[];
  @Input() ordenacao: string;
  @Input() placeholder = 'Filtre';
  @Input() delineado = true;
  @Input() desabilitado = false;

  selectedItems = [];
  onChange: any;
  onTouched: any;
  form: UntypedFormGroup;
  control: UntypedFormControl = null;
  elementoSemFoco = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      itens: this.formBuilder.control(null),
    });
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get<any>(NgControl as any);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
      if (this.control.invalid) {
        this.placeholder = this.placeholder + ' *';
      }
    }
    this.cdr.detectChanges();
  }

  defineElementoSemFoco() {
    this.elementoSemFoco = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.listaDeOpcoes &&
      !changes.listaDeOpcoes.isFirstChange() &&
      changes.listaDeOpcoes.previousValue &&
      changes.listaDeOpcoes.previousValue.length
    ) {
      this.selectedItems = [];
      this.form.reset();
    }

    // Atualizando ordenação das opções
    if (this.listaDeOpcoes) {
      this.listaDeOpcoes.sort();
      if (this.ordenacao) {
        this.listaDeOpcoes.sort((a, b) => a[this.ordenacao].localeCompare(b[this.ordenacao]));
      }
    }
  }

  writeValue(value): void {
    if (value) {
      if (this.dropdownSettings.singleSelection) {
        this.selectedItems = [];
      }
      const valores = Array.isArray(value) ? value : [value];
      valores.forEach((item) => {
        const novoItem = {};
        novoItem[this.dropdownSettings.idField] = item.id;
        novoItem[this.dropdownSettings.textField] =
          item[this.dropdownSettings.textField];
        this.selectedItems.push(novoItem);
      });
    } else {
      this.selectedItems = [];
    }
    this.form.get('itens').setValue(this.selectedItems);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(selected: any) {
    if (selected !== null) {
      const selecao = [selected];
      if (this.dropdownSettings.singleSelection) {
        this.selectedItems = [];
      }
      selecao.forEach((item) => {
        const encontrado = this.encontraSelecionado(item);
        if (encontrado) {
          this.selectedItems.push(encontrado);
        }
      });
      this.onChange(
        this.dropdownSettings.singleSelection ? this.selectedItems[0] : this.selectedItems
      );
    } else {
      this.onChange(this.dropdownSettings.singleSelection ? selected : this.selectedItems);
    }
  }

  onDeSelect(item: any) {
    const _id = this.dropdownSettings.idField;
    this.selectedItems = this.selectedItems.filter(x => x[_id] !== item[_id]);
    if (this.dropdownSettings.singleSelection) {
      this.onChange(null);
    } else {
      this.onChange(this.selectedItems);
    }
  }

  private encontraSelecionado(selecionado: any) {
    return this.listaDeOpcoes.find((opcao) => opcao.id === selecionado.id);
  }

  /**
   * Chamado pelo onSelectAll e pelo onDeSelectAll.
   *
   * @param event evento recebido. No onSelectAll, recebe toda a lista disponível, no onDeselectAll, recebe um evento
   * vazio que limpa os itens selecionados.
   */
  marcarEDesmarcarTodos(event: any) {
    this.selectedItems = event;
    this.onChange(event);
  }
}
