import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@dft/security/service/auth.service';
import { TipoPerfilEnum } from '@dft/shared/enums/tipo-perfil.enum';
import { Orgao } from '@dft/shared/models/orgao';
import { Perfil } from '@dft/shared/models/perfil';
import { Pessoa } from '@dft/shared/models/pessoa';
import { Unidade } from '@dft/shared/models/unidade';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import { PerfilService } from '@dft/shared/services/perfil.service';

@Component({
  templateUrl: './perfil-form.component.html',
  styleUrls: ['./perfil-form.component.scss'],
})
export class PerfilFormComponent implements OnInit {
  @ViewChild('filtro') elementoFiltro: ElementRef;

  TipoPerfilEnum: typeof TipoPerfilEnum = TipoPerfilEnum;

  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  pessoa: Pessoa;
  ehGestorGeral: boolean;
  exibeListaDeUnidades = false;
  listaDeUnidades: Unidade[] = [];
  listaDePerfis: Perfil[] = [];
  exibeSelecaoPerfilValidador = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _authservice: AuthService,
    private _orgaoService: OrgaoService,
    private _perfilService: PerfilService,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this._formBuilder.group({
      id: [''],
      pessoa: [null],
      perfil: [null, Validators.required],
      unidade: [null],
      isPerfilValidador: [true],
    });
    this.form.get('perfil').valueChanges.subscribe(perfil => this.atualizaPerfilSelecionado(perfil));
  }

  async obtemUnidadesDaPessoa(idOrgao: number) {
    this.listaDeUnidades = await this._orgaoService
      .buscarUnidadesFilhas(idOrgao)
      .toPromise();

    this.listaDeUnidades = Orgao.montarBindName(this.listaDeUnidades);

    if (this.listaDeUnidades.length === 1) {
      this.form.get('unidade').setValue(this.listaDeUnidades[0]);
    }
  }

  ngOnInit() {
    this._perfilService.listarPerfis().subscribe(result => {
      this.listaDePerfis = result;
      this.listaDePerfis.sort((a, b) => a.descricao.localeCompare(b.descricao));
      this.ehGestorGeral = this._authservice.isGestorGeral();
      if (this.formValue) {
        this.pessoa = this.formValue.pessoa;
        this.form.setValue({
          id: this.formValue.pessoa.id,
          pessoa: this.formValue.pessoa,
          unidade: null,
          perfil: null,
          isPerfilValidador: true,
        });

        if (this.listaDePerfis.length === 1) {
          this.form.get('perfil').setValue(this.listaDePerfis[0]);
        }
        this.obtemUnidadesDaPessoa((this.formValue.pessoa as Pessoa).orgao.id);
      }
      this.cdr.detectChanges();
    });
  }

  private atualizaPerfilSelecionado(perfilSelecionado?: Perfil): void {
    this.exibeSelecaoPerfilValidador = false;
    this.exibeListaDeUnidades = false;
    const formUnidade = this.form.get('unidade');
    formUnidade.setValue(null);
    formUnidade.clearValidators();

    if (!perfilSelecionado) {
      return;
    }

    if (perfilSelecionado.descricao === TipoPerfilEnum.GESTOR_UNIDADE
      || perfilSelecionado.descricao === TipoPerfilEnum.GESTOR_ORGAO) {
      this.exibeSelecaoPerfilValidador = true;
    }

    if (
      perfilSelecionado.descricao === TipoPerfilEnum.GESTOR_UNIDADE ||
      perfilSelecionado.descricao === TipoPerfilEnum.USUARIO
    ) {
      formUnidade.setValidators([Validators.required]);
      this.exibeListaDeUnidades = true;
      if (this.listaDeUnidades.length === 1) {
        formUnidade.setValue(this.listaDeUnidades[0]);
      } else {
        formUnidade.setValue(null);
      }
    } else {
      formUnidade.setValue(this.pessoa.orgao as Unidade);
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
