import { TipoAutenticador } from "@dft/shared/enums/tipo-autenticador.enum";

export const environment = {
  // Original do DFT
  production: false,
  urlApi: 'http://localhost:8080/governo/gft/v1.0/api',
  version: '1.0.0-beta',
  autenticacao: {
    tipo: TipoAutenticador.OAUTH,
  },

  // SEAD (basedo no projeto Smart)
  isToUseAuthentication: true,
  authorizationUrl: 'https://ssodesenv.go.gov.br/api/identity/entitlement/decision',
  apiPortalAcessos: 'https://apidesenv.go.gov.br/portal/acessos-usuarios/v1.0',
  apiPortalMenusSistemas: 'https://apidesenv.go.gov.br/portal/menus-sistemas/v1.0',
  apiRhVinculos: 'https://apidesenv.go.gov.br/rh/vinculos/v1.0',
  idSistemaPortal: 'GFT',
  sso: {
    clientId: 'FsY1dIaYAlYq2kGGxfdTwz_hBXIa',
    serverUrl: 'https://ssodesenv.go.gov.br',
    issuer: '/oauth2/oidcdiscovery',
    redirectUri: window.location.origin,
    scope: 'openid profile email offline_access',
    showDebugInformation: true,
    urlCheckSession: '/oidc/checksession',
    responseType: 'code',
  }
};
