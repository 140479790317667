import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { DimensionamentoEntrega } from '@dft/shared/models/dimensionamento-entrega';

@Component({
  selector: 'dft-alterar-descricao-entrega-form',
  templateUrl: './alterar-descricao-entrega-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AlterarDescricaoEntregaFormComponent implements FormComponent {
  // Atributos do FormComponent
  form: UntypedFormGroup;
  readonly: boolean;
  entrega: DimensionamentoEntrega;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      apelidoEntrega: this.fb.control(''),
      apelidoAtividade: this.fb.control(''),
      apelidoFluxoTrabalho: this.fb.control(''),
      apelidoCodigo:  this.fb.control({value: '', disabled: true}),
      apelidoFonte: this.fb.control(''),
    });
  }

  setFormValue(value: DimensionamentoEntrega): void {
    this.entrega = value;
    const {
      apelidoEntrega,
      apelidoAtividade,
      apelidoFluxoTrabalho,
      apelidoCodigo,
      apelidoFonte,
    } = this.entrega;
    this.form.setValue({
      apelidoEntrega: apelidoEntrega || value.entregaNome,
      apelidoAtividade: apelidoAtividade || value.atividade,
      apelidoFluxoTrabalho: apelidoFluxoTrabalho || value.fluxoTrabalho,
      apelidoCodigo: apelidoCodigo || value.entregaDto.codigo,
      apelidoFonte: apelidoFonte || value.fonte,
    });
  }

  setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
