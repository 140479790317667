import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { Entrega } from '@dft/shared/models/entrega';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './entrega-form.component.html',
  styleUrls: ['./entrega-form.component.scss'],
})
export class EntregaFormComponent implements OnInit, FormComponent {

  form: UntypedFormGroup;
  categorias: CategoriaServico[];
  formValue: any;
  readonly = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      nome: ['', Validators.required],
      codigo: [{ value: '', disabled: true }, Validators.required],
      codBaseEntrega: [{ value: '', disabled: true }, Validators.required],
      categoriaServico: ['', Validators.required],
      atividade: ['', Validators.required],
      fluxoTrabalho: ['', Validators.required],
      fonte: [null],
      status: [null, Validators.required],
      justificativa: [null],
      // Informações de formação
      formacao: [null],
      _grandeArea: [null],
      _areaBasica: [null],
      _especialidade: [null],
      especialidades: [[]],
      // Informações de área de conhecimento
      cadeiaValor: [null],
      capacidade: [null],
      interacao: [null],
      natureza: [null],
    });
  }

  public setFormValue(value: Entrega): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.form.patchValue(this.formValue);
      if (this.formValue.categoriaServico) {
        this.form.controls.codBaseEntrega.setValue(this.formValue.categoriaServico.codBaseEntrega);
      }
    }
  }
}
