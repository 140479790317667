import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Orgao } from '@dft/shared/models/orgao';

@Component({
  templateUrl: './orgao-gratificacao-form.component.html',
  styleUrls: ['./orgao-gratificacao-form.component.scss']
})
export class OrgaoGratificacaoFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  orgao: Orgao;

  constructor(
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      orgao: this._formBuilder.control(''),
      nome: this._formBuilder.control(null),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.orgao = this.formValue.orgao;
      if (this.formValue) {
        let id = 0;
        let nome = '';
        if (this.formValue.gratificacao) {
          id = this.formValue.gratificacao.id;
          nome = this.formValue.gratificacao.nome;
        }
        this.form.setValue({
          id,
          orgao: this.formValue.orgao,
          nome,
        });
      }
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

}
