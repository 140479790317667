import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Menu } from './menu';
import { OpcoesMenuService } from './opcoes-menu.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  menus: Menu[] = undefined;
  isWidth600 = false;
  subscriptions: Subscription[] = [];

  constructor(
    public opcoesMenuService: OpcoesMenuService,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    const subs1 = this.opcoesMenuService.menus$.subscribe(menus => {
      this.menus = menus;
      this.changeDetector.detectChanges();
    });

    const subs2 = this.breakpointObserver.observe('(max-width: 600px)').subscribe(state => {
      this.isWidth600 = state.matches;
    });
    this.subscriptions.push(subs1, subs2);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(subs => subs.unsubscribe());
    }
  }

}
