import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './indicador-form.component.html',
  styleUrls: ['./indicador-form.component.scss'],
})
export class IndicadorFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      nome: [''],
      descricao: ['', Validators.required],
      tipo: [''],
      ativo: ['']
    });
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.form.setValue(this.formValue);
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

}
