import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Vinculo } from '../models/rhnet/vinculo.model';
import { SeadAuthService } from './sead-auth.service';

@Injectable({ providedIn: 'root' })
export class RhNetService {

  constructor(
    private http: HttpClient,
    private authService: SeadAuthService
  ) { }

  findViculoByCpf(): Observable<Vinculo> {
    return this.http.get<Vinculo>(`${environment.apiRhVinculos}/${this.authService.getCpf()}`,);
  }
}
