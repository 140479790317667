import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Entrega } from '@dft/shared/models/entrega';

@Component({
  selector: 'dft-entrega-view',
  templateUrl: './entrega-view.component.html',
})
export class EntregaViewComponent {

  entrega: Entrega;
  form: UntypedFormGroup;
  readonly = false;

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  public setFormValue(value: Entrega): void {
    this.entrega = value;
  }

}
