import {
  Component,
  ElementRef, EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { SpinnerService } from '@dft/shared/dialog/spinner/spinner.service';
import { Escolaridade } from '@dft/shared/models/escolaridade';
import { Orgao } from '@dft/shared/models/orgao';
import { Pessoa } from '@dft/shared/models/pessoa';
import { EscolaridadeService } from '@dft/shared/services/escolaridade.service';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import { PessoaService } from '@dft/shared/services/pessoa.service';
import { cpfValidator } from '@dft/shared/util/cpf-validator';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { MensagemService } from './../../../shared/services/mensagem.service';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};

@Component({
  templateUrl: './pessoa-form.component.html',
  styleUrls: ['./pessoa-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PessoaFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  @ViewChild('inputNome') inputNome: ElementRef<
    HTMLInputElement
  >;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pessoaService: PessoaService,
    private orgaoService: OrgaoService,
    private escolaridadeService: EscolaridadeService,
    private spinnerService: SpinnerService,
    private mensagemService: MensagemService
  ) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(''),
      cpf: this.formBuilder.control('', [Validators.required, cpfValidator]),
      nome: this.formBuilder.control('', Validators.required),
      dataNascimento: this.formBuilder.control('', Validators.required),
      telefone: this.formBuilder.control(''),
      orgao: this.formBuilder.control(undefined, Validators.required),
      sexo: this.formBuilder.control(undefined),
      escolaridade: this.formBuilder.control(undefined),
    });
  }
  @Output() formularioEnviado: EventEmitter<any> = new EventEmitter();

  pessoa: Pessoa = new Pessoa();
  orgaoSelecionado: Orgao;
  listaDeOrgaos: Orgao[];
  escolaridades: Escolaridade[] = [];
  dataNascimentoSelecionada = moment(null);

  mask = '0000999999999';

  get nome() {
    return this.form.get('nome');
  }

  ngOnInit() {
    this.orgaoService.listarOrgaos().subscribe((orgaos) => {
      this.getListaDeOrgaos(orgaos);
    });

    if (this.formValue) {
      this.buscarEscolaridadesDoOrgao(this.formValue.orgao.id);

      this.orgaoSelecionado = this.formValue.orgao;

      this.formValue.orgao.bindName =
        this.formValue.orgao.sigla + ' - ' + this.formValue.orgao.descricao;

      this.form.setValue({
        id: this.formValue.id,
        cpf: this.formValue.cpf,
        nome: this.formValue.nome,
        dataNascimento: this.formValue.dataNascimento,
        telefone: this.formValue.telefone,
        orgao: this.formValue.orgao,
        sexo: this.formValue.sexo,
        escolaridade: this.formValue.escolaridade,
      });
    }
  }

  getListaDeOrgaos(orgaos: Orgao[]): void {
    orgaos.map((orgao) => {
      orgao.bindName = `${orgao.sigla} - ${orgao.descricao}`;
    });

    this.listaDeOrgaos = orgaos;
    if (orgaos.length === 1) {
      this.form.controls['orgao'].setValue(this.listaDeOrgaos[0]);
      this.orgaoSelecionado = this.listaDeOrgaos[0];
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  carregarDados(pessoa: Pessoa, webService?: boolean): void {
    const {
      nome,
      cpf,
      dataNascimento,
      sexo,
      escolaridade,
    } = pessoa;

    const orgao = this.form.get('orgao').value;
    const id = this.form.get('id').value + '';
    orgao.bindName = `${orgao.sigla} - ${orgao.descricao}`;
    const telefone = pessoa.telefone;

    if (escolaridade && !this.escolaridades.find(e => e.nome === escolaridade.nome)) {
      this.escolaridades.push(escolaridade);
    }

    this.form.setValue({
      id,
      cpf,
      nome,
      telefone,
      orgao,
      dataNascimento,
      sexo,
      escolaridade,
    });
    this.dataNascimentoSelecionada = moment(pessoa.dataNascimento);
    this.inputNome.nativeElement.click();
  }

  buscarDadosPeloCPF(cpf: string): void {
    const spinner = this.spinnerService.start();
    this.pessoaService
      .buscarDadosDaPessoaNoOrgao(this.form.get('orgao').value, cpf)
      .pipe(
        finalize(() => {
          this.spinnerService.stop(spinner);
        })
      )
      .subscribe(
        (pessoa) => {
          this.carregarDados(pessoa, true);
        },
        (error) => {
          this.mensagemService.erro(error);
        }
      );
  }

  adaptaMascaraDeTelefone(fone) {
    const tamanho = fone.value.replace(/\D/g, '').length;

    if (tamanho < 5) {
      this.mask = '0000999999999';
    } else if (tamanho > 4 && tamanho < 11) {
      this.mask = '(00) 0099-99999';
    } else {
      this.mask = '(00) 0 0999-9999';
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  public selecionarOrgao(event: any) {
    if (
      event &&
      event.id &&
      (this.orgaoSelecionado === undefined ||
        this.orgaoSelecionado.id !== event.id)
    ) {
      this.buscarEscolaridadesDoOrgao(event.id);
      this.orgaoSelecionado = event;
    }
  }

  buscarEscolaridadesDoOrgao(idOrgao: number) {
    this.escolaridadeService
      .listarEscolaridades(idOrgao)
      .subscribe((escolaridades) => {
        this.escolaridades = escolaridades || [];
      });
  }
}
