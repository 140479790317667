import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@dft/security/service/auth.service';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { STATUS_INDICADOR_MAP, StatusIndicadorProdutividadeEnum } from '@dft/shared/enums/status-indicador-produtividade.enum';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { CategoriaServicoService } from '@dft/shared/services/categoria-servico.service';
import { IndicadorProdutividadeService } from '@dft/shared/services/indicador-produtividade.service';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './indicador-produtividade-form.component.html',
  styleUrls: ['./indicador-produtividade-form.component.scss'],
})
export class IndicadorProdutividadeFormComponent implements OnInit {
  form: UntypedFormGroup;
  categorias$: Observable<CategoriaServico[]>;
  formValue = null;
  readonly = false;
  isGestorGeral = false;

  statusIndicadorMap = STATUS_INDICADOR_MAP;
  statusIndicador = StatusIndicadorProdutividadeEnum;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private mensagemService: MensagemService,
    private service: CategoriaServicoService,
    private _authService: AuthService,
    private _indicadorProdutividadeService: IndicadorProdutividadeService
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      nome: ['', Validators.required],
      categoriaServico: [null, Validators.required],
      codigo: ['', Validators.required],
      status: ['', Validators.required]
    });
    this.isGestorGeral = this._authService.isGestorGeral();
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  ngOnInit(): void {

    if (this.formValue) {
      this.form.patchValue(this.formValue);
      this.form.controls.status.setValue(this.statusIndicadorMap.find(f => f.id === this.formValue.status));
    } else {
      this.form.controls.status.setValue(this.statusIndicadorMap.find(f => f.id === StatusIndicadorProdutividadeEnum.NAO_VALIDADO));
      this.form.controls.categoriaServico.valueChanges.subscribe(
        val => this.buscarSugestaoCodigoIndicadorProdutividade(val)
      );
    }
    this.categorias$ = this.service.listarCategorias().pipe(
      map(categorias => categorias.filter(c => c.tipo === TipoMetodologiaEnum.ATIPICA)),
      tap(categorias => {
        if (this.formValue?.id) {
          const categoriaSelecionada = categorias.find(c => c.id === this.formValue.categoriaServico.id);
          this.form.controls.categoriaServico.setValue(categoriaSelecionada);
        }
      }),
      catchError(err => {
        this.mensagemService.erro(err);
        return [];
      })
    );
  }

  private buscarSugestaoCodigoIndicadorProdutividade(categoria?: CategoriaServico) {
    if (categoria?.id) {
      this._indicadorProdutividadeService.buscarSugestaoCodigoIndicadorProdutividade(categoria.id)
        .subscribe((result) => {
          this.form.controls.codigo.setValue(result ? result : '');
        });
    }
  }
}
