import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StatusDimensionamentoEnum } from '@dft/shared/enums/status-dimensionamento.enum';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { Orgao } from '@dft/shared/models/orgao';
import { DimensionamentoService } from '@dft/shared/services/dimensionamento.service';
import { OrgaoService } from '@dft/shared/services/orgao.service';


@Component({
  templateUrl: 'consolidacao-form.component.html',
  styleUrls: ['./consolidacao-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsolidacaoFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  listaDeDimensionamentos: Dimensionamento[] = [];
  listaDeOrgaos: Orgao[] = [];
  ehGestorGeral = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _orgaoService: OrgaoService,
    private _dimensionamentoService: DimensionamentoService
  ) {
    this.form = this._formBuilder.group({
      orgao: [null, Validators.required],
      dimensionamentos: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.ehGestorGeral = this.formValue['isGestorGeral'];
    }
    this.obtemListaDeOrgaos();

    this.form.get('orgao').valueChanges.subscribe(() => {
      this.obtemListaDeDimensionamentos();
    });

    this.form.get('dimensionamentos').valueChanges.subscribe(() => {
      this.atualizaInterfaceDimensionamentosSelecionados();
    });
  }

  private obtemListaDeOrgaos(): void {
    this._orgaoService.listarOrgaos().subscribe(orgaos => {
      // Adicionando atributo "bindName" para exibir no form
      this.listaDeOrgaos = orgaos.map(orgao =>
        ({ ...orgao, bindName: `${orgao.sigla} - ${orgao.descricao}` }));

      if (orgaos.length === 1) {
        this.form.controls.orgao.setValue(orgaos[0]);
        this.form.controls.orgao.disable();
        this.obtemListaDeDimensionamentos();
      }
    });
  }

  private obtemListaDeDimensionamentos(): void {
    this.form.controls.dimensionamentos.setValue([]);
    const orgaoSelecionado = this.form.controls.orgao.value;
    if (orgaoSelecionado) {
      this._dimensionamentoService
        .listarDimensionamentosDoOrgao(orgaoSelecionado.id, StatusDimensionamentoEnum.DIMENSIONADO)
        .subscribe(dimensionamentos => {
          this.listaDeDimensionamentos = dimensionamentos;
          this.listaDeDimensionamentos.sort((a, b) => {
            const orgaoCompare = a.unidade.orgao.sigla.localeCompare(b.unidade.orgao.sigla);
            if (orgaoCompare !== 0) {
              return orgaoCompare;
            }
            return a.unidade.sigla.localeCompare(b.unidade.sigla);
          });
        });
    }
  }

  private periodosSaoIguais(dims: Dimensionamento[]): boolean {
    const periodo = {
      dataInicio: dims[0].dataInicio,
      dataFim: dims[0].dataFim,
    };

    return dims.every(dim =>
      dim.dataInicio === periodo.dataInicio &&
      dim.dataFim === periodo.dataFim
    );
  }

  private metodologiasSaoIguais(dims: Dimensionamento[]): boolean {
    const metodologia = dims[0].tipo;
    return dims.every((dim) => dim.tipo === metodologia);
  }

  atualizaInterfaceDimensionamentosSelecionados(): void {
    const dimensionamentosSelecionados =
      this.form.controls.dimensionamentos.value as Dimensionamento[];

    if (
      dimensionamentosSelecionados &&
      dimensionamentosSelecionados.length > 0
    ) {
      this.form.controls.dimensionamentos.setErrors(null);

      if (!this.periodosSaoIguais(dimensionamentosSelecionados)) {
        this.form.controls.dimensionamentos.setErrors({ periodosDiferentes: true });
      } else if (dimensionamentosSelecionados.length === 1) {
        this.form.controls.dimensionamentos.setErrors({ unicaSelecao: true });
      }
      if (!this.metodologiasSaoIguais(dimensionamentosSelecionados)) {
        this.form.controls.dimensionamentos.setErrors({ metodologiasDiferentes: true });
      }
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
