<app-header></app-header>
<app-nav></app-nav>
<div class="app-container">
  <div class="app-inner-container">
    <div class="conteudo-container">
      <!-- Main content -->
      <section class="conteudo">
        <router-outlet></router-outlet>
      </section>
    </div>
    <app-footer></app-footer>
  </div>
</div>
