import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { StatusDimensionamentoPipe } from './pipes/status-dimensionamento.pipe';
import { DescricaoPerfilPipe } from './pipes/descricao-perfil.pipe';
import { MoedaRealPipe } from './pipes/moeda-real.pipe';
import { QuantidadePipe } from './pipes/quantidade.pipe';
import { TotalHoras } from './pipes/total-horas.pipe';
import { CpfPipe } from './pipes/cpf.pipe';
import { CodigoFormatadorPipe } from './pipes/codigo-formatador.pipe';
import { SeparadorListaPipe } from './pipes/separador-lista.pipe';
import { MesAnoPipe } from './pipes/mes-ano.pipe';

import { DateMaskDirective } from './directives/date-mask.directive';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';

import { DialogComponent } from './dialog/dialog.component';
import { DialogFormComponent } from './dialog/dialog-form.component';
import { DialogDirective } from './dialog/dialog.directive';
import { SpinnerComponent } from './dialog/spinner/spinner.component';
import { SpinnerService } from './dialog/spinner/spinner.service';

import { BotaoVoltarComponent } from './components/botao-voltar/botao-voltar.component';
import { MultiselectWrapperComponent } from './components/multiselect-wrapper/multiselect-wrapper.component';
import { ListaCorGradienteDirective } from './directives/lista-cor-gradiente.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        NgMultiSelectDropDownModule,
        RouterModule,
    ],
    declarations: [
        BotaoVoltarComponent,
        CdkDetailRowDirective,
        CpfPipe,
        MesAnoPipe,
        CodigoFormatadorPipe,
        DateMaskDirective,
        DialogComponent,
        DialogDirective,
        DialogFormComponent,
        MoedaRealPipe,
        QuantidadePipe,
        SeparadorListaPipe,
        SpinnerComponent,
        StatusDimensionamentoPipe,
        DescricaoPerfilPipe,
        TotalHoras,
        MultiselectWrapperComponent,
        ListaCorGradienteDirective
    ],
    exports: [
        BotaoVoltarComponent,
        CdkDetailRowDirective,
        CpfPipe,
        MesAnoPipe,
        CodigoFormatadorPipe,
        DateMaskDirective,
        MoedaRealPipe,
        QuantidadePipe,
        SeparadorListaPipe,
        SpinnerComponent,
        StatusDimensionamentoPipe,
        DescricaoPerfilPipe,
        TotalHoras,
        MultiselectWrapperComponent,
        ListaCorGradienteDirective
    ],
    providers: [SpinnerService]
})
export class SharedModule {}
