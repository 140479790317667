import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './parametro-form.component.html'
})
export class ParametroFormComponent implements OnInit, FormComponent {

    form: UntypedFormGroup;
    formValue = null;
    readonly = false;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            id: this.formBuilder.control(''),
            nome: this.formBuilder.control({ value: '', disabled: true }, Validators.required),
            valor: this.formBuilder.control('', Validators.required),
            descricao: this.formBuilder.control({ value: '', disabled: true }, Validators.required),
            dataAlteracao: this.formBuilder.control(''),
        });
    }


    public setFormValue(value: object): void {
        this.formValue = value;
    }

    public setFormReadOnly(readonly: boolean): void {
        this.readonly = readonly;
    }

    ngOnInit(): void {
        if (this.formValue) {
            this.form.setValue(this.formValue);
        }
    }

}
