import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { CategoriaServicoService } from '@dft/shared/services/categoria-servico.service';
import { of } from 'rxjs';
import { TIPO_METODOLOGIA_MAP } from 'src/app/shared/enums/metodologia.enum';
import { CategoriaServico } from '../../../../shared/models/categoria-servico';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './categoria-servico-form.component.html',
  styleUrls: ['./categoria-servico-form.component.scss'],
})
export class CategoriaServicoFormComponent implements OnInit {

  form: UntypedFormGroup;
  metodologias = [
    { id: 0, descricao: TIPO_METODOLOGIA_MAP.get(TipoMetodologiaEnum.TIPICA) },
    { id: 1, descricao: TIPO_METODOLOGIA_MAP.get(TipoMetodologiaEnum.ATIPICA) }
  ];
  formValue: CategoriaServico = null;
  readonly = false;
  codigoBaseEntregaOriginal = '';
  $codigoBaseEntregaAlterado = of(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private categoriaService: CategoriaServicoService
  ) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(''),
      descricao: this.formBuilder.control('', Validators.required),
      tipo: this.formBuilder.control('', Validators.required),
      codBaseEntrega: this.formBuilder.control('', Validators.required)
    });
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  ngOnInit(): void {
    if (!this.formValue) {
      this.buscarSugestaoCodigoBaseCategoriaEntrega();
      return;
    }

    this.form.setValue({
      id: this.formValue.id,
      descricao: this.formValue.descricao,
      tipo: { id: this.formValue.id, descricao: TIPO_METODOLOGIA_MAP.get(this.formValue.tipo) },
      codBaseEntrega: this.formValue.codBaseEntrega
    });
    this.codigoBaseEntregaOriginal = this.formValue.codBaseEntrega;

    // Controla as alterações do código base para avisar o usuário
    this.form.controls.codBaseEntrega.valueChanges.subscribe(value => {
      const isCategoriaServicoExistente = !!this.formValue.id;
      const exibirMensagemUsuario = isCategoriaServicoExistente && this.codigoBaseEntregaOriginal !== value;
      this.$codigoBaseEntregaAlterado = of(exibirMensagemUsuario);
    });
  }

  private buscarSugestaoCodigoBaseCategoriaEntrega() {
    this.categoriaService.buscarSugestaoCodigoBaseCategoriaEntrega()
      .subscribe((result) => {
        this.form.controls.codBaseEntrega.setValue(result);
        this.codigoBaseEntregaOriginal = result;
      });
  }
}
