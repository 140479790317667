<form [formGroup]="form">

  <mat-form-field appearance="outline">
    <mat-label>Dimensionamento</mat-label>
    <input
      matInput
      name="dimensionamento"
      [value]="dimensionamento.nome"
      readonly=true
    />
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="isDimensionamentoTipico()">
    <mat-label>Índice de Tempo Produtivo (%)</mat-label>
    <input
      matInput
      name="indice"
      type="number"
      step="0.1"
      min="0"
      max="100"
      placeholder="Ex.: 37.65"
      formControlName="indice"
    />
    <mat-hint>Insira o valor em porcentagem.</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="isDimensionamentoTipico()">
    <mat-label>Projeção (%)</mat-label>
    <input
      matInput
      name="meta"
      type="number"
      step="0.1"
      min="0"
      max="100"
      placeholder="Ex.: 15.65"
      formControlName="meta"
    />
    <mat-hint>Preencha caso deseje incluir uma projeção de meta nos resultados das entregas.</mat-hint>
  </mat-form-field>

  <br />
  <br />
  
  Personalizar período de cálculo?
  <mat-slide-toggle formControlName="personalizacaoPeriodo" (change)="definePersonalizacaoPeriodo($event)"
    aria-label="Marque para personalizar o período a ser calculado">
  </mat-slide-toggle>
  <br />
  <small class="aviso-periodo-data" *ngIf="form.get('dataInicio').errors?.dataForaPeriodo">
    Data extrapola período do dimensionamento.
  </small>
  <div *ngIf="personalizacaoPeriodoMarcada">
    <mat-form-field appearance="outline">
      <mat-label>Data Inicial</mat-label>
      <input aria-label="Data Inicial"
        matInput
        [matDatepicker]="dpInicio"
        placeholder="Ex.: 01/2018"
        formControlName="dataInicio"
        name="data-inicial"/>
      <mat-datepicker-toggle matSuffix [for]="dpInicio"></mat-datepicker-toggle>
      <mat-datepicker #dpInicio
        startView="multi-year"
        (monthSelected)="escolhaMesInicioHandler($event, dpInicio)">
      </mat-datepicker>
      <mat-hint>A data deve estar dentro do período do dimensionamento. Mínimo permitido: {{dimensionamento.dataInicio | date: 'MM/yyyy' }}.</mat-hint>
    </mat-form-field>

    <small class="aviso-periodo-data" *ngIf="form.get('dataFim').errors?.dataForaPeriodo">
      Data extrapola período do dimensionamento.
    </small>
    <mat-form-field appearance="outline">
      <mat-label>Data Final</mat-label>
      <input aria-label="Data Final"
        matInput
        [matDatepicker]="dpFim"
        placeholder="Ex.: 12/2018"
        formControlName="dataFim"
        name="data-final"/>
      <mat-datepicker-toggle matSuffix [for]="dpFim"></mat-datepicker-toggle>
      <mat-datepicker #dpFim
        startView="multi-year"
        (monthSelected)="escolhaMesFimHandler($event, dpFim)">
      </mat-datepicker>
      <mat-hint>A data deve estar dentro do período do dimensionamento. Máximo permitido: {{dimensionamento.dataFim | date: 'MM/yyyy' }}.</mat-hint>
    </mat-form-field>
  </div>
</form>
