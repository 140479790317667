import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';

@Component({
  selector: 'dft-consolidacao-edit-descricao',
  templateUrl: './consolidacao-edit-descricao.component.html',
  styleUrls: ['./consolidacao-edit-descricao.component.scss']
})
export class ConsolidacaoEditDescricaoComponent implements AfterViewInit, FormComponent {

  form: UntypedFormGroup;
  formValue: Dimensionamento;
  readonly = false;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control({ value: 0 }),
      descricao: this.formBuilder.control({ value: '' }, Validators.required),
    });
  }

  ngAfterViewInit(): void {
    this.form.patchValue(this.formValue);
  }

  public setFormValue(value: Dimensionamento): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
