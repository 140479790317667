import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { DimensionamentoEntrega } from '@dft/shared/models/dimensionamento-entrega';

@Component({
  templateUrl: './quantificacao-entrega-form.component.html',
  styleUrls: ['./quantificacao-entrega-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuantificacaoEntregaFormComponent implements OnInit, FormComponent {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  entrega: DimensionamentoEntrega;
  metaAtivo: boolean;

  constructor(private fb: UntypedFormBuilder) {
    const validarCampoPreenchido: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
      let algumPreenchido = false;
      (control.controls.resultados as UntypedFormArray).controls.forEach(resultado => {
        const quantidade = resultado.get('quantidade');
        const meta = resultado.get('meta');
        if ((quantidade && quantidade.value > 0) || (meta && meta.value > 0)) {
          algumPreenchido = true;
        }
      });

      if (this.form) {
        this.form.markAsTouched();
        if (!algumPreenchido) {
          return { invalid: true };
        }
      }

      return null;
    };

    this.form = this.fb.group({
      resultados: new UntypedFormArray([]),
      demandaReprimida: this.fb.control(null),
    }, { validators: validarCampoPreenchido });
  }

  ngOnInit() {
    this.entrega = this.formValue.entrega as DimensionamentoEntrega;
    this.metaAtivo = this.formValue.dimensionamento.metaAtivo as boolean;
    this.entrega.resultados.forEach(resultado => {
      this.resultados.push(this.fb.group({
        ...resultado,
        quantidade: this.fb.control(resultado.quantidade),
        meta: this.fb.control(resultado.meta)
      }));
    });
    this.form.controls['demandaReprimida'].setValue(this.formValue.entrega.demandaReprimida);
  }
  get resultados(): UntypedFormArray {
    return this.form.get('resultados') as UntypedFormArray;
  }
  public setFormValue(de: DimensionamentoEntrega): void {
    this.formValue = de;
  }
  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
