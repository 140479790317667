<form [formGroup]="form">
  <div>
    <input type="hidden" formControlName="id" />

    <ng-container>
      <mat-label aria-hidden="true">
        Órgão
      </mat-label>
      <dft-multiselect
        formControlName="orgao"
        placeholder="Selecione o Órgão"
        campoDeTexto="bindName"
        [desabilitado]="readonly"
        [delineado]="true"
        [selecaoUnica]="true"
        [listaDeOpcoes]="listaDeOrgaos | async"
        ordenacao="descricao"
        [permitirFiltroDePesquisa]="true"
      >
      </dft-multiselect>
    </ng-container>

    <br />

    <ng-container>
      <mat-label aria-hidden="true">
        Unidade
      </mat-label>
      <dft-multiselect
        formControlName="unidade"
        placeholder="Selecione a Unidade"
        campoDeTexto="bindName"
        [desabilitado]="readonly"
        [delineado]="true"
        [selecaoUnica]="true"
        [listaDeOpcoes]="listaDeUnidades | async"
        ordenacao="descricao"
        [permitirFiltroDePesquisa]="true"
      >
      </dft-multiselect>
    </ng-container>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Observação</mat-label>
      <textarea aria-label="Observação"
        matInput
        rows="3"
        name="descricao"
        placeholder="Ex.: Dimensionamento do RH 2020"
        formControlName="descricao"
        #descricao
        (ngModelChange)="form.controls['descricao'].markAsTouched()"
      ></textarea>
      <mat-error *ngIf="form.controls['descricao'].invalid">{{obtemMensagemDeErro()}}</mat-error>
    </mat-form-field>

    <div class="flex-form-container">
      <mat-form-field appearance="outline" class="flex-ini-date">
        <mat-label>Data Inicial</mat-label>
        <input aria-label="Data Inicial"
          matInput
          [matDatepicker]="dpInicio"
          placeholder="Ex.: 01/2018"
          formControlName="dataInicio"
          name="data-inicial"
          (change)="normalizaDataDigitada($event)"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="dpInicio"></mat-datepicker-toggle>
        <mat-datepicker
          #dpInicio
          startView="multi-year"
          (yearSelected)="escolhaAnoInicioHandler($event)"
          (monthSelected)="escolhaMesInicioHandler($event, dpInicio)"
        >
        </mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="flex-fin-date">
        <mat-label>Data Final</mat-label>
        <input aria-label="Data Final"
          matInput
          [matDatepicker]="dpFim"
          placeholder="Ex.: 12/2018"
          formControlName="dataFim"
          name="data-final"
          (change)="normalizaDataDigitada($event)"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="dpFim"></mat-datepicker-toggle>
        <mat-datepicker
          #dpFim
          startView="multi-year"
          (yearSelected)="escolhaAnoFimHandler($event)"
          (monthSelected)="escolhaMesFimHandler($event, dpFim)"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>

    <ng-container>
      <mat-label aria-hidden="true">
        Metodologia
      </mat-label>
      <dft-multiselect
        formControlName="tipo"
        placeholder="Selecione a Metodologia"
        campoDeTexto="descricao"
        [desabilitado]="readonly"
        [delineado]="true"
        [selecaoUnica]="true"
        [listaDeOpcoes]="listaDeMetodologias"
      >
      </dft-multiselect>
    </ng-container>

    <br />

  </div>

  <mat-divider></mat-divider>
  <br />

  <div>
    <ng-container>
      <mat-label aria-hidden="true">Dimensionamento a ser Clonado</mat-label>
      <dft-multiselect
        aria-label="Selecione Dimensionamento a ser Clonado"
        formControlName="dimensionamentoClonado"
        placeholder="Selecione Dimensionamento a ser Clonado (opcional)"
        campoDeTexto="nome"
        [desabilitado]="readonly"
        [delineado]="true"
        [selecaoUnica]="true"
        [listaDeOpcoes]="listaDeDimensionamentos"
        [permitirFiltroDePesquisa]="true"
      >
      </dft-multiselect>
    </ng-container>
  </div>

  <br>

  <div>
    Adicionar Metas?
    <mat-slide-toggle color="primary" formControlName="isMetaAtivo"></mat-slide-toggle>
  </div>

  <div>
    Por registro de produção?
    <mat-slide-toggle color="primary" formControlName="registroProducao"></mat-slide-toggle>
  </div>

</form>
