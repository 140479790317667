import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CAPACIDADE_MAP } from '@dft/shared/enums/capacidade.enum';
import { INTERACAO_MAP } from '@dft/shared/enums/interacao.enum';
import { NATUREZA_MAP } from '@dft/shared/enums/natureza.enum';
import { CadeiaValor } from '@dft/shared/models/cadeia-valor';
import { Entrega } from '@dft/shared/models/entrega';
import { CadeiaValorService } from '@dft/shared/services/cadeia-valor.service';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'dft-entrega-areas-conhecimento',
  templateUrl: './entrega-areas-conhecimento.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaAreasConhecimentoComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  entrega: Entrega;

  cadeiaValores$: Observable<CadeiaValor[]>;
  capacidades = CAPACIDADE_MAP;
  interacoes = INTERACAO_MAP;
  naturezas = NATUREZA_MAP;

  constructor(
    private cadeiaValorService: CadeiaValorService,
    private mensagemService: MensagemService
  ) { }

  ngOnInit() {
    this.carregarCadeiaValores();
    this.preSelecionarValores();
  }

  private preSelecionarValores() {
    if (this.entrega) {
      const capacidadeAux = this.capacidades.find(f => f.id === this.entrega.capacidade);
      if (capacidadeAux) {
        this.entrega.capacidade = capacidadeAux;
        this.form.controls.capacidade.setValue(capacidadeAux);
      }

      const interacaoAux = this.interacoes.find(f => f.id === this.entrega.interacao);
      if (interacaoAux) {
        this.entrega.interacao = interacaoAux;
        this.form.controls.interacao.setValue(interacaoAux);
      }

      const naturezaAux = this.naturezas.find(f => f.id === this.entrega.natureza);
      if (naturezaAux) {
        this.entrega.natureza = naturezaAux;
        this.form.controls.natureza.setValue(naturezaAux);
      }
    }
  }

  private carregarCadeiaValores() {
    this.cadeiaValores$ = this.cadeiaValorService.listarCadeiaValores().pipe(
      tap(cadeiaValores => {
        if (this.entrega && this.entrega.cadeiaValor) {
          const cadeiaSelecionada = cadeiaValores.find(c => c.id === this.entrega.cadeiaValor.id);
          this.form.controls.cadeiaValor.setValue(cadeiaSelecionada);
        }
      }),
      catchError(err => {
        this.mensagemService.erro(err);
        return [];
      })
    );
  }
}
