import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { SeadAuthService } from '../service/sead-auth.service';

type GuardActivation =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private oauthService: OAuthService,
    private seadAuthService: SeadAuthService,
    // private acessosUsuarioService: AcessosUsuarioService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardActivation {
    return this.podeProsseguir(next, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardActivation {
    return this.podeProsseguir(next, state);
  }

  private podeProsseguir(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardActivation {
    if (!this.seadAuthService.isValidToken()) {
      this.oauthService.initImplicitFlow();
      return false;
    }

    // Verifica se necessário perfil para autenticar
    const perfil = this.authService.perfilSelecionado$.value;
    if (perfil && next.data.roles?.indexOf(perfil.descricaoPerfil) === -1) {
      // Perfil não autorizado a acessar a página. Redireciona para erro 403.
      this.router.navigate(['/error/403']);
      return false;
    }

    // Caso o usuário possui sessão.
    return true;
  }
}
