import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home/home.component';
import { AuthGuard } from './security/guards/auth.guard';
import { AuthService } from './security/service/auth.service';
import { TipoPerfilEnum } from './shared/enums/tipo-perfil.enum';

const routes: Routes = [
  {
    path: 'dimensionamento',
    loadChildren:
      () => import('./modules/dimensionamento/dimensionamento.module').then(m => m.DimensionamentoModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },
  {
    path: 'pessoa-perfil',
    loadChildren:
      () => import('./modules/pessoa-perfil/pessoa-perfil.module').then(m => m.PessoaPerfilModule),
    canActivate: [AuthGuard],
    data: {
      roles: [
        TipoPerfilEnum.GESTOR_GERAL,
        TipoPerfilEnum.GESTOR_ORGAO,
        TipoPerfilEnum.GESTOR_UNIDADE,
      ],
    },
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resultados',
    loadChildren:
      () => import('./modules/dimensionamento/dimensionamento.module').then(m => m.DimensionamentoModule),
    canActivate: [AuthGuard],
    data: {
      roles: [TipoPerfilEnum.GESTOR_GERAL],
    },
  },
  {
    path: 'relatorios',
    loadChildren:
      () => import('./modules/relatorios/relatorios.module').then(m => m.RelatoriosModule),
    canActivate: [AuthGuard],
    data: {
      roles: [
        TipoPerfilEnum.GESTOR_GERAL,
        TipoPerfilEnum.GESTOR_ORGAO,
      ],
    },
  },
  {
    path: 'tipificacao',
    loadChildren:
      () => import('./modules/tipificacao/tipificacao.module').then(m => m.TipificacaoModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren:
      () => import('./modules/gestao-perfil/gestao-perfil.module').then(m => m.GestaoPerfilModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'configuracao',
    loadChildren:
      () => import('./modules/configuracao/configuracao.module').then(m => m.ConfiguracaoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'success',
    loadChildren:
      () => import('./modules/success-pages/success-pages.module').then(m => m.SuccessPagesModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
  exports: [RouterModule],
  providers: [AuthGuard, AuthService],
})
export class AppRoutingModule { }
