import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { IndicadorProdutividade } from '@dft/shared/models/indicador-produtividade';
import { UnidadeService } from '@dft/shared/services/unidade.service';
import { getStringSemAcento } from '@dft/shared/util/string.util';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './selecao-indicadores-produtividade-form.component.html',
  styleUrls: ['./selecao-indicadores-produtividade-form.component.scss'],
})
export class SelecaoIndicadoresProdutividadeFormComponent implements OnInit, FormComponent {

  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  indicadores$ = new BehaviorSubject<IndicadorProdutividade[]>([]);
  indicadoresOriginais: IndicadorProdutividade[];
  filtro = '';

  listaDeCategoriasServico: CategoriaServico[];
  categoriaSelecionada: CategoriaServico;

  constructor(private fb: UntypedFormBuilder, private unidadeService: UnidadeService) {
    // O objeto abaixo armazenará apenas os IDs dos indicadores selecionados.
    // É o que será retornado para a tela anterior
    this.form = this.fb.group({
      indicadoresSelecionados: new UntypedFormArray([])
    });
  }

  ngOnInit(): void {
    const idUnidade = this.formValue.unidade.id as number;
    const indicadoresAnteriores = this.formValue.indicadores as IndicadorProdutividade[];

    this.unidadeService
      .listarProdutividades(idUnidade)
      .subscribe(indicadoresTotais => {
        // Separa os indicadores pendentes de inclusão na lista do dimensionamento
        const indicadoresRestantes = indicadoresTotais
          .filter(indicador => !indicadoresAnteriores.some(anterior => anterior.id === indicador.id));

        this.indicadores$.next(indicadoresRestantes);
        this.indicadoresOriginais = indicadoresRestantes;

        this.listarCategoriasServico(indicadoresRestantes);
      });
  }

  listarCategoriasServico(indicadores: IndicadorProdutividade[]) {
    this.listaDeCategoriasServico = indicadores
    .map(indicador => indicador.categoriaServico)
    .filter((item, i, arr) => arr.findIndex((t) => t.id === item.id) === i);
  }

  selecionar(id: number): void {
    if (this.isSelecionado(id)) {
      // Recupera o índice e remove o ID do indicador do FormArray
      const index = this.indicadoresSelecionados.controls.findIndex(control => control.value.id === id);
      this.indicadoresSelecionados.removeAt(index);

    } else {
      // Inclui o ID no FormArray
      this.indicadoresSelecionados.push(this.fb.group({ id }));
    }
  }

  selecionarTodos() {
    this.indicadores$.pipe(take(1)).subscribe(indicadores => {
      indicadores.forEach(ind => {
        if (!this.isSelecionado(ind.id)) {
          this.indicadoresSelecionados.push(this.fb.group({ id: ind.id }));
        }
      });
    });
  }

  isSelecionado(id: number): boolean {
    return this.indicadoresSelecionados.controls.some(control => control.value.id === id);
  }

  filtrarPorCategoriaServico(selecionada?: any) {
    this.categoriaSelecionada = selecionada;

    const indicadoresAnteriores = this.formValue.indicadores as IndicadorProdutividade[];
    const indicadoresRestantes = this.indicadoresOriginais
          .filter(indicador => !indicadoresAnteriores.some(anterior => anterior.id === indicador.id));

    let listaFiltrada: IndicadorProdutividade[];

    if (this.categoriaSelecionada && this.categoriaSelecionada.id) {
      listaFiltrada = indicadoresRestantes.filter(ind =>
        ind.categoriaServico && ind.categoriaServico.id === this.categoriaSelecionada.id);
    } else {
      listaFiltrada = indicadoresRestantes;
    }

    this.indicadores$.next(listaFiltrada);
  }

  filtrar(): void {
    const filtroSemAcento = getStringSemAcento(this.filtro);
    const listaFiltrada = this.indicadoresOriginais.filter(ind => getStringSemAcento(ind.nome).includes(filtroSemAcento));
    this.indicadores$.next(listaFiltrada);
  }

  limparFiltro() {
    this.filtro = '';
    this.indicadores$.next(this.indicadoresOriginais);
  }

  get indicadoresSelecionados(): UntypedFormArray {
    return this.form.get('indicadoresSelecionados') as UntypedFormArray;
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
