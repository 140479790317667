import { Injectable } from '@angular/core';
import { ActionID } from '../models/portal/action-id.model';
import { AuthStore } from './auth-store.service';

@Injectable({ providedIn: 'root' })
export class AcessosUsuarioService {

  private actionsUsuarios: ActionID[] | undefined = [];
  private roles: string[] = [];

  constructor(private authStore: AuthStore) {
  }

  getActionsUsuario(): ActionID[] | undefined {
    return this.actionsUsuarios;
  }

  getRoles(): string[] {
    return this.roles;
  }

  hasRole(role: string[] | string): boolean {
    if (!role)
      return false;
    if (!Array.isArray(role)) {
      return this.roles.includes(role);
    }
    for (let index in role) {
      if (this.roles.includes(role[index])) {
        return true;
      }
    }
    return false;
  }

  isNotEmptyRoles() {
    return this.roles && this.roles.length > 0;
  }

  async load(): Promise<ActionID[] | undefined> {
    this.roles = this.actionsUsuarios.map(a => a['um_attr_value'])
    this.authStore.setState(this.roles);
    return this.actionsUsuarios;
  }
}
