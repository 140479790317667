import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AcessoUsuario } from './security/models/usuario/acesso-usuario.model';
import { Usuario } from './security/models/usuario/usuario.model';
import { AcessosUsuarioService } from './security/service/acesso-usuario.service';
import { PortalService } from './security/service/portal.service';
import { RhNetService } from './security/service/rh.service';
import { SeadAuthService } from './security/service/sead-auth.service';
import { SessionStorageService } from './security/service/session-storage.service';
import { Vinculo } from './shared/models/vinculo';

@Injectable({ providedIn: 'root' })
export class AppService {

  private _acessosUsuario: AcessoUsuario | undefined;
  private _vinculo: Vinculo | undefined;

  constructor(private portalService: PortalService,
    private rhnetService: RhNetService,
    private router: Router,
    private authService: SeadAuthService,
    private sessionStorageService: SessionStorageService,
    private acessosUsuarioService: AcessosUsuarioService,
  ) { }

  async loadAccessData(): Promise<void> {
    try {
      if (this.authService.isValidToken()) {
        await this.acessosUsuarioService.load();
      } else {
        this.sessionStorageService.clear();
      }
    } catch (error) {
      alert('Erro interno ao buscar dados da aplicação')
    } finally {
      this.router.initialNavigation();
    }
  }

  async loadAppData(): Promise<void> {
    try {
      if (this.authService.isValidToken()) {
        this._acessosUsuario = await this.findAcessosUsuario();
        this._vinculo = await this.findVinculoUsuario();
        this.sessionStorageService.set('app_acessosUsuario', this._acessosUsuario);
      }
    } catch (error) {
      alert('Erro interno ao buscar dados da aplicação')
    }
  }

  async findAcessosUsuario(): Promise<AcessoUsuario | undefined> {
    if (!this.sessionStorageService.isExist('app_acessos')) {
      return this.portalService.findAcessosUsuario().toPromise();
    } else {
      return this.sessionStorageService.get('app_acessos');
    }
  }

  async findVinculoUsuario(): Promise<Vinculo | undefined> {
    if (!this.sessionStorageService.isExist('app_vinculo')) {
      try {
        let vinculo = await this.rhnetService.findViculoByCpf().toPromise();
        if (vinculo) {
          return <any>vinculo;
        }
        return new Vinculo();
      } catch (err) {
        alert('Erro ao recuperar vínculo: ' + err.error.detail);
        return new Vinculo();
      }
    }
    return this.sessionStorageService.get('app_vinculo');
  }

  getVinculo(): Vinculo {
    return (<any>this._vinculo)[0];
  }

  getUsuario(): Usuario {
    return this?._acessosUsuario ? this?._acessosUsuario : (<any>[0])?.usuario;
  }

  getAcessosUsuario(): AcessoUsuario | undefined {
    return this._acessosUsuario;
  }

  isAppDataOk(): boolean {
    // @ts-ignore
    return this._acessosUsuario['perfis'].length > 0;
  }
}
