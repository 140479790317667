import { Component, OnInit } from '@angular/core';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { authConfig } from './security/auth-config';
import { AuthService } from './security/service/auth.service';
import { SessionStorageService } from './security/service/session-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    appService: AppService,
    oauthService: OAuthService,
    sessionStorageService: SessionStorageService,
  ) {
    oauthService.configure(authConfig);

    /** enable below validation only if jwks object is defined as part of oauthconfig obj */
    oauthService.tokenValidationHandler = new JwksValidationHandler();
    oauthService.setStorage(sessionStorageService.getSessionStorage());

    oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(isLoaded => {
        oauthService.sessionCheckIFrameUrl += `?client_id=${environment.sso.clientId}&redirect_uri=${window.location.origin}`;
        // @ts-ignore
        oauthService.config.sessionCheckIFrameUrl = oauthService.sessionCheckIFrameUrl;
        appService.loadAccessData();
      });

    oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        oauthService.logOut();
      }
    });
  }

  ngOnInit() {
    this.authService.carregarSessao();
  }
}
