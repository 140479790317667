import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from './spinner.component';

/**
 * Camada de serviço que invoca um "spinner" na tela
 * para operações lentas.
 *
 * Basicamente, basta declarar este service na sua classe
 * e invocar os métodos <code>start()</code> e <code>stop()</code>,
 * passando a referência retornada pelo método <code>start()</code>.
 *
 * Veja: {@link https://www.c-sharpcorner.com/article/use-angular-material-dialog-as-spinner2/}
 */
@Injectable()
export class SpinnerService {

  constructor(private dialog: MatDialog) { }

  start(message?): MatDialogRef<SpinnerComponent> {
    return this.dialog.open(SpinnerComponent, {
      disableClose: true,
      data: message || 'Aguarde...'
    });
  }

  stop(ref: MatDialogRef<SpinnerComponent>) {
    ref.close();
  }
}
