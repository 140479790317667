import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DimensionamentoEntrega } from '@dft/shared/models/dimensionamento-entrega';
import { FormComponent } from '@dft/shared/dialog/dialog.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './info-etapas.component.html',
  styleUrls: ['./info-etapas.component.scss'],
})
export class InfoEtapasComponent implements OnInit, FormComponent {
  form: UntypedFormGroup;
  formValue: any;
  readonly = true;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      fluxoTrabalho: [''],
      atividade:     [''],
      entrega:       [''],
      fonte:         [''],
    });
  }

  public setFormValue(value: DimensionamentoEntrega): void {
    this.formValue = {
      fluxoTrabalho: value.apelidoFluxoTrabalho || value.fluxoTrabalho,
      atividade:     value.apelidoAtividade     || value.atividade,
      entrega:       value.apelidoEntrega       || value.entregaNome,
      fonte:         value.apelidoFonte         || value.fonte
    };
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.form.setValue(this.formValue);
    }
  }
}
