import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cadeia-valor-form.component.html',
  styleUrls: ['./cadeia-valor-form.component.scss'],
})
export class CadeiaValorFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      id: [''],
      nome: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.form.setValue(this.formValue);
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
