import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TipoIndicadorEnum } from '@dft/shared/enums/tipo-indicador.enum';
import { DimensionamentoIndicador } from '@dft/shared/models/dimensionamento-indicador';

@Component({
  templateUrl: 'quantificacao-indicadores-pessoal-form.component.html',
  styleUrls: ['quantificacao-indicadores-pessoal-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuantificacaoIndicadoresPessoalFormComponent implements OnInit {
  form: UntypedFormGroup;
  formValue = null;
  readonly = false;

  TipoIndicadorEnum: typeof TipoIndicadorEnum = TipoIndicadorEnum;

  indicador: DimensionamentoIndicador;

  constructor(private fb: UntypedFormBuilder) {
    const validarCampoPreenchido: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
      const resultados = (control.controls.resultados as UntypedFormArray).controls;
      const algumPreenchido = resultados.find(resultado => {
        const quantidade = resultado.get('quantidade');
        return quantidade && quantidade.value > 0;
      });

      if (this.form) {
        this.form.markAsTouched();
        if (!algumPreenchido) {
          return { invalid: true };
        }
      }

      return null;
    };

    this.form = this.fb.group(
      { resultados: new UntypedFormArray([]) },
      { validators: validarCampoPreenchido }
    );
  }

  get resultados(): UntypedFormArray {
    return this.form.get('resultados') as UntypedFormArray;
  }

  ngOnInit() {
    this.indicador = this.formValue['indicador'] as DimensionamentoIndicador;
    this.indicador.resultados.sort((a, b) => a.dataResultado.localeCompare(b.dataResultado));
    this.indicador.resultados.forEach(resultado => {
      this.resultados.push(
        this.fb.group({
          ...resultado,
          quantidade: this.fb.control(resultado.quantidade)
        })
      );
    });
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
