import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { MenuModule } from './nav/menu/menu.module';
import { InfoUsuarioComponent } from './header/info-usuario.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        NavComponent,
        InfoUsuarioComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        NavComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatTabsModule,
        MatMenuModule,
        MatDialogModule,
        MenuModule
    ]
})
export class LayoutModule { }
