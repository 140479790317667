  <br/>

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Categoria de serviço
    </mat-label>
    <input aria-label="Nome da categoria de serviço"
      matInput
      name="categoriaServico"
      [value]="entrega?.categoriaServico?.descricao"
      readonly="true"
    />
  </mat-form-field>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">Código da Entrega</mat-label>
    <input aria-label="Código da Entrega com 6 dígitos"
      matInput
      name="codigo"
      [value]="entrega.codigo"
      readonly="true"
    />
  </mat-form-field>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">Entrega</mat-label>
    <input aria-label="Nome da Entrega"
      matInput
      name="nome"
      [value]="entrega.nome"
      readonly="true"
    />
  </mat-form-field>

  <br />
  
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Atividade
    </mat-label>
    <input aria-label="Atividade"
      matInput
      name="atividade"
      [value]="entrega.atividade"
      readonly="true"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Fluxo de Trabalho
    </mat-label>
    <textarea
      matInput aria-label="Fluxo de Trabalho"
      name="fluxoTrabalho"
      cdkTextareaAutosize
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="5"
      [value]="entrega.fluxoTrabalho"
      readonly="true"
    ></textarea>
  </mat-form-field>
  

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">Fonte</mat-label>
    <input
      matInput aria-label="Fonte"
      name="fonte"
      [value]="entrega.fonte"
      readonly="true"
    />
  </mat-form-field>
